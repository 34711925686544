import axios from 'axios'

export const booksInThread = async function(parent_tweet_id){
    const baseURL = `${process.env.GATSBY_API_URL}`
    const apiUrl = baseURL + '/woa/' + parent_tweet_id
    
    const response = await axios( apiUrl, {
        headers: {
          'X-Api-Key': `${process.env.GATSBY_API_KEY}`
        }
      })  

    return response 

}