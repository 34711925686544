import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom";
import { Row } from "react-bootstrap"
import { navigate } from 'gatsby';
import {Helmet, HelmetProvider } from 'react-helmet-async';

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import TweetQuestion from "../../components/tweetquestion"
import Book from "../../components/book"
import { booksInThread } from '../../services/books-in-thread'
import '../../components/thread/style.css'

const Thread = (props) =>{
    const parentTweetID = props.id
    const [ parentTweet, setParentTweet ] = useState()
    const [ woa, setWOA ] = useState([])
    const [matchfound, setMatchFound] = useState(false)
    const [description, setDescription] = useState()
    const [image, setOgImage] = useState()
    let title = "Book Recommendations"
  
    useEffect(() => {
        function getBookThumbnail(woa_list){
            woa_list.map(woa =>{
                if(woa.thumbnail != null)
                    return woa.thumbnail
            })
            return null
        }

        async function fetchData(){
            let response = null
            
            if(parentTweetID)
                response = await booksInThread(parentTweetID)

            if(response && response.hasOwnProperty('data') && response.data.question.length > 0){
                setParentTweet(response.data.question[0])
                setDescription(response.data.question[0].text)
                
                if( typeof response.data.woa !== 'undefined' 
                    && response.data.woa.length > 0)
                {
                    setWOA(response.data.woa)
                    const thumbnail = getBookThumbnail(response.data.woa)
                    setOgImage(thumbnail)
                    setMatchFound(true)
                }
                else{
                    navigate('/404')
                }
            }
            else {
                navigate('/404')
            }
        }
        fetchData()
    }, [parentTweetID])

    return(
        <React.Fragment>
            {matchfound ?
            <div>
            <React.Fragment>
            <Layout>
                <SEO title={title} description={description} image={image}/>
                <Row>
                    <TweetQuestion tweet={parentTweet}/>
                </Row>
                <Row>
                    <h1 style={{marginTop:"2rem", marginBottom:"1rem"}}>Book mentions in this thread</h1>
                </Row>
                <Row>
                    <ul className="booklist">
                        {woa.map( woa_item => {
                            const book = woa_item.books[0]
                            const score = woa_item.score
                            if(typeof book != "undefined" && 
                                book != null && 
                                book.hasOwnProperty('isbn'))
                            {
                                const id = parentTweet._id + '_' + book.isbn
                                return(
                                <li key={id}>
                                    <Book book={book} showDescription={true} score={score}/>
                                </li>
                                )
                            }        
                        })}
                    </ul> 
                </Row> 
            </Layout>
            </React.Fragment></div>: null }
        </React.Fragment>
    )
}

export default Thread;